<template>
  <v-dialog
    :value="isShow"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ title }}</span>
      </v-card-title>

      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent="onSubmitForm"
      >
        <v-card-text>
          <component
            :is="messageComponent"
            v-model="message"
            :placeholder="placeholder"
            :rules="rules"
            :items="options"
            :show-by="selectShowBy"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="handleCloseClick"
          >
            {{ cancelText }}
          </v-btn>
          <v-btn
            type="submit"
            color="blue darken-1"
            text
          >
            {{ saveText }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Form from '@/mixins/form'

const RccTextarea = () => import('Components/controls/rcc-textarea')
const RccTextInput = () => import('Components/controls/rcc-text-input')
const RccSelect = () => import('Components/controls/rcc-select')

export default {
  name: 'RccMessageDialog',

  mixins: [Form],

  data() {
    return {
      message: ''
    }
  },

  props: {
    isShow: {
      type: Boolean,
      default: false
    },

    rules: {
      type: Array,
      default: () => []
    },

    title: {
      type: String,
      default: ''
    },

    saveText: {
      type: String,
      default: 'Сохранить'
    },

    saveCallback: {
      type: Function,
      default: null
    },

    cancelText: {
      type: String,
      default: 'Закрыть'
    },

    messageComponentType: {
      type: String,
      default: 'textarea'
    },

    placeholder: {
      type: String,
      default: ''
    },

    options: {
      type: [Array, undefined],
      default: () => []
    },

    selectShowBy: {
      type: String,
      default: 'name'
    }
  },

  computed: {
    messageComponent() {
      switch(this.messageComponentType) {
        case 'input':
          return RccTextInput

        case 'select':
          return RccSelect

        case 'textarea':
        default:
          return RccTextarea
      }
    }
  },

  methods: {
    handleCloseClick() {
      this.$emit('update:is-show', false)
    },

    handleSaveClick() {
      if (this.saveCallback) {
        return this.saveCallback(this.message)
      }

      return Promise.resolve()
    },

    onSubmit() {
      return this.handleSaveClick()
    }
  }
}
</script>

<style lang="scss" scoped>
.v-card__text {
  padding-bottom: 0 !important;
}
</style>
